body {
    font-size: 12pt;
    font-family: 'Signika', sans-serif;
    font-weight: 300;
}


.left-margin {
    margin-left: 30px;
}

.list-group-item span {
    padding-top: 3px;
}

.list-group-item span span {
    padding: 0;
}

.nav-tabs .nav-link {
    border-color: rgba(0, 0, 0, 0.125);
    border-bottom-color: rgba(0, 0, 0, 0);
}

.nav-tabs .nav-item.active .nav-link {
    border-bottom-color: #fff;
}

.navbar-nav .nav-item.active {
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 4px;
}

.navbar-nav .nav-item a {
    color: #3a3a3a;
}

#mobile-navigation .nav-item a {
    padding-left: 15px;
}

#printable-section {
    font-family: Arial, Helvetica, sans-serif;
}

.modal-backdrop.in {
    opacity: 0.5;
    filter: alpha(opacity=50);
}

.modal-xl {
    max-width: 1400px;
}

.btn {
    white-space: nowrap;
}

.btn.btn-xs {
    padding: 0 .3rem;
}

.form-horizontal .control-label {
    font-weight: bold;
    text-align: right;
    padding-top: 7px;
}

.note-editable.card-block {
    min-height: 200px;
}

.lawcard-wrapper {
    background-color: rgba(0, 0, 0, 0.05);
}

.list-group-item:hover {
    z-index: 0;
}

.modal-dialog {
    overflow-y: initial !important;
    width: fit-content;
}

.modal-content {
    max-height: 90vh;
}

.modal-body {
    /*max-height: 85vh;*/
    overflow-y: auto;
}

.note-editor.note-frame {
    /* max-height: 50vh; */
}

.note-editor .note-editing-area .note-editable {
    max-height: 90vh;
}

.note-editor.note-frame.fullscreen {
    height: 100vh;
    max-height: 100vh;
}

.note-editor.note-frame.fullscreen .note-editable.card-block {
    max-height: 100vh;
}


/* Inactive tabs */
.nav-tabs .nav-item {
    margin-left: 2px;
}

.nav-tabs .nav-item:not(.active) {
    margin-top: 4px;
}

.nav-tabs .nav-item:not(.active) .nav-link {
    background-color: rgba(0, 0, 0, 0.03);
    padding-bottom: 4px;
    color: rgb(128, 128, 128);
}

.header-subtitle {
    padding-top: 10px;
    margin-bottom: 0;
}